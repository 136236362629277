
$(() => {

  //
  $('#identity_type').on('change', function() {
    const value = $(this).val();
    if (value) {
      $('#identity_number').prop('disabled', false);
    } else {
      $('#identity_number').prop('disabled', true);
    }
  });
  $('#identity_type').trigger('change');

});