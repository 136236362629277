

$(() => {

  $(document).on('click', '.one-click', function() {
    const el = $(this);
    el.addClass('disabled');
    setTimeout(() => {
      el.removeClass('disabled');
    }, 3000);
  });

  $(document).on('click', '.disabled', function(e) {
    e.preventDefault();
  });
});

