
// css
require('../scss/main.scss');

require('./debounce');

// jquery
require('./auto-toggle');
require('./select-search');
require('./notifications');
require('./clickable-row');
require('./zipcodes');
require('./autocomplete');
require('./select-foldertype');
require('./auto-submit');
require('./date-range-picker');
require('./messages-chat');
require('./document-upload');
require('./tooltips');
require('./copy-to-clipboard');
require('./refresh');
require('./one-click');
require('./agent/users');
require('./agent/table-sort');
require('./agent/folders');
require('./agent/blocks');
require('./beneficiary-form');
require('./agent/mentions');

require('./global/applicant');
require('./global/timezone');
require('./global/studies');

require('./partenaire/main');

// alpine.js
require('./document-panel');
require('./alpine-modal');


$(() => {
  // delete confirm
  $(document).on('click', '[data-confirm]', function() {
    const message = $(this).data('confirm');
    return window.confirm(message);
  });

  // tom-select
  window.initTomSelect = (container) => {
    $('select.tom-select', container).each(function() {
      const el = $(this);
      if (el.data('initialized')) {
        return;
      }
      const create    = el.data('option-creation');
      const multiple  = el.prop('multiple');
      new window.TomSelect(el.get(0), {
        hidePlaceholder: true,
        create,
        plugins: multiple ? ['remove_button',] : [],
        maxOptions: null,
        onInitialize: function() {
          this.wrapper.classList.remove('hidden');
        },
        render:{
          option_create: (data, escape) => {
            return '<div class="create">Ajouter <strong>' + escape(data.input) + '</strong>&hellip;</div>';
          },
          no_results: () => {
            return '<div class="no-results">Aucun résultat trouvé</div>';
          },
        }
      });
      el.data('initialized', true);
    });
  };
  window.initTomSelect('body');

});