$(() => {
  
  // sources
  const SOURCES = {
    communes: {
      url: 'https://geo.api.gouv.fr/communes?limit=15&nom=',
      transform: data => {
        data.forEach(d => {
          d.codePostal = d.codesPostaux[0];
        });
        return data;
      },
      selected: result => {
        $('input[name="birth_city"]').val(result.nom);
        $('input[name="birth_zipcode"]').val(result.codePostal);
      }
    },
    regions: {
      url: 'https://geo.api.gouv.fr/regions?limit=15&nom=',
      transform: data => {
        return data;
      },
      selected: (result, prefix, ul) => {
        $(ul).parent().find('input').val(result.nom);
      }
    },
    adresses: {
      url: 'https://api-adresse.data.gouv.fr/search/?limit=15&q=',
      transform: data => {
        return data.features.map(f => f.properties);
      },
      selected: (result, prefix) => {
        $(`input[name=${prefix}address]`).val(result.name);
        $(`input[name=${prefix}address1]`).val(result.name);
        $(`input[name=${prefix}address_lpof]`).val('');
        $(`input[name=${prefix}zipcode]`).val(result.postcode);
        $(`input[name=${prefix}city]`).val(result.city);
        $(`select[name=${prefix}city]`).data('value', result.city);
        $(`input[name=${prefix}city]`).val(result.city);
        $(`input[name=${prefix}zipcode]`).trigger('change');
      }
    },
    airports_stations: {
      stations_url: '/stations/search?q=',
      airports_url : '/airports/search?q=',
      selected: (result, prefix, ul) => {
        const airport_id = result.id;
        const station_code = result.id ? undefined : result.code;
        
        const parent = $(ul).parent();
        parent.find('input[data-type=airport_id]').val(airport_id);
        parent.find('input[data-type=station_code]').val(station_code);
        parent.find('input[type=text]').val(result.alias_libelle_noncontraint);
      }
    }
  };

  // autocomplete
  const autocomplete = function() {
    var q   = $(this).val();
    if (q.length < 4) {
      return;
    }
    const $input = $(this);
    const source = SOURCES[$input.data('src')];

    const ul = $($input.parent().find('ul.autocomplete-list'));
    const li = ul.find('li.hidden');
    
    if (source.paramWithQuote) {
      q = '\''+q+'\'';
    }
    $.get(source.url + encodeURIComponent(q), function(data) {
      data = source.transform(data);
      if (!data || data.length === 0) {
        ul.addClass('hidden');
        return;
      }
      ul.find('li:not(.hidden)').remove();
      ul.removeClass('hidden');
      for (const result of data) {
        const item = li.clone();
        item.removeClass('hidden');
        item.data('result', result);
        item.data('source', source);
        Object.keys(result).forEach(key => {
          item.find(`[data-attr="${key}"]`).text(result[key]);
        });
        ul.append(item);
      }
    });
  };

  // autocomplete
  const autocompleteAirportAndStations = function() {
    var q   = $(this).val();
    if (q.length < 3) {
      return;
    }
    const $input = $(this);
    const source = SOURCES[$input.data('src')];

    const ul = $($input.parent().find('ul.autocomplete-list'));
    const li = ul.find('li.hidden');
    
    // Appel AJAX pour les gares
    $.get(source.stations_url + encodeURIComponent(q), function(data1) {
      // Appel Ajax pour les aéroports
      $.get({
        url: source.airports_url + encodeURIComponent(q),
        success: function(data2) {
          // Fusion des résultats des deux sources de données
          let mergedData = data2.concat(data1);
          if (!mergedData || mergedData.length === 0) {
            ul.addClass('hidden');
            return;
          }

          // orderning by name
          mergedData = mergedData.sort((a, b) => a.alias_libelle_noncontraint?.localeCompare(b.alias_libelle_noncontraint));

          ul.find('li:not(.hidden)').remove();
          ul.removeClass('hidden');
          for (const result of mergedData) {
            const item = li.clone().prop('id', result.id ?? result.code);
            item.removeClass('hidden');
            item.data('result', result);
            item.data('source', source);
            Object.keys(result).forEach(key => {
              item.find(`[data-attr="${key}"]`).text(result[key]);
            });
            ul.append(item);
          }
        },
      });
    });
  };

  const dismiss = function() {
    setTimeout(() => {
      const ul = $($(this).parent().find('ul.autocomplete-list'));
      ul.addClass('hidden');
    }, 300);
  };

  $(document).on('keyup', 'input.autocomplete', debounce(autocomplete, 300));
  $(document).on('focus', 'input.autocomplete', autocomplete);
  $(document).on('blur',  'input.autocomplete', dismiss);
  $(document).on('click', 'button.btn-autocomplete', function() { autocomplete.apply($(this).parent().find('input.autocomplete').trigger('focus')); });

  $(document).on('keyup', 'input.autocompleteAirportAndStations', debounce(autocompleteAirportAndStations, 300));
  $(document).on('focus', 'input.autocompleteAirportAndStations', autocompleteAirportAndStations);
  $(document).on('blur',  'input.autocompleteAirportAndStations', dismiss);
  $(document).on('click', 'button.btn-autocompleteAirportAndStations', function() { autocompleteAirportAndStations.apply($(this).parent().find('input.autocompleteAirportAndStations').trigger('focus')); });

  $(document).on('click', 'ul.autocomplete-list > li', function() {
    const ul      = $(this).parent();
    const prefix  = ul.data('prefix') || '';

    const source  = $(this).data('source');
    const result  = $(this).data('result');

    source.selected(result, prefix, ul);
  });

});