
$(() => {

  const notificationbutton = $('#notifications-button');
  if (!notificationbutton.length) {
    return;
  }

  // refresh notifications (AJAX)
  const refresh = () => {
    $.get('/ajax-notifications', function(html) {
      if (!$('#notifications-dropdown').is(':visible')) {
        $('#notifications-wrapper').html(html);
      }
    });
  };

  refresh();
  // refresh every 10 seconds
  setInterval(refresh, 10000);

});

$(document).on('click', '.deleteNotification', function(event) {
  event.preventDefault();
  // Récupère l'URL à partir de l'attribut data-url
  var url = $(this).data('url');
  $.ajax(url, {
    success: function(data) {
      $('#notifications-wrapper').html(data);
    }
  });

});

$(document).on('click', '.deleteAllNotifications', function(event) {
  event.preventDefault();
  // Récupère l'URL à partir de l'attribut data-url
  var url = $(this).data('url');
  $.ajax(url, {
    success: function(data) {
      $('#notifications-wrapper').html(data);
    }
  });

});