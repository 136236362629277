

$(() => {

  if (!$('select[name=foldertype]').length) {
    return;
  }

  $(document).on('change', 'select[name=foldertype], input[name=applicant_id]', function() {
    const href = $(this).closest('form').data('href');
    const foldertype    = $('select[name=foldertype]').val();
    const applicant_id  = $('input[name=applicant_id]').val();

    const url = [
      href,
      foldertype ? `/${foldertype}` : '',
      applicant_id ? `?applicant_id=${applicant_id}` : '',
    ].join('');

    window.location.href = url;
  });

});