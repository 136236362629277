
$(() => {

  $('select[name="project_degree_id"]').on('change', function() {
    const level = $(this).find(':selected').data('level');
    $('input[name="project_degree_level"]').val(level);
  });

  $(document).on('change', 'input[name="formation_school_time"], input[name="formation_company_working_time"]', function() {
    const school = parseInt($('input[name="formation_school_time"]').val()) || 0;
    const company = parseInt($('input[name="formation_company_working_time"]').val()) || 0;
    const total = school + company;
    $('input[name="formation_total_time"]').val(total);
  });

  //
  $('input[name="is_monthly"]').on('change', function() {
    if ($(this).val()) {
      $('.monthly_amount').show();
      $('.not_monthly').hide();
    } else {
      $('.monthly_amount').hide();
      $('.not_monthly').show();
    }
  });
  $('input[name="is_monthly"]:checked').trigger('change');

  //
  $(document).on('change', 'select[name="formation_welcoming_delegation_id"]', function() {
    const id = $(this).val();
    $.get(`/users?delegation_id=${id}`, function(data) {
      const $refSelect = $('select[name="formation_welcoming_referent_id"]');
      $refSelect.find('option').not(':first').remove(); // remove old options
      if (!data.users || data.users.length === 0) {
        return;
      }
      $.each(data.users, function(key, user) {
        $refSelect.append($('<option></option>').attr('value', user.id).text(user.name));
      });
    });
  });
});
