


$(() => {

  $('input[name="to_bill"]').on('change', function() {
    console.log('change');
    if ($(('input[name="to_bill"]:checked')).length > 0) {
      $('button[type="submit"]').removeAttr('disabled');
    } else {
      $('button[type="submit"]').attr('disabled', 'disabled');
    }

    const vouchers_ids = $('input[name="to_bill"]:checked').map(function() {
      return $(this).data('id');
    });

    $('input[name="vouchers_ids"]').val(vouchers_ids.get());
  });
});
