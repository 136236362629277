$(() => {

  $(document).on('mouseover', '[data-tooltip]', function() {
    
    // Create tooltip element
    const tooltipText = $(this).attr('data-tooltip');
    
    const tooltip = $('<div></div>').text(tooltipText)
    .addClass('absolute z-10 p-2 bg-gray-800 text-white whitespace-nowrap text-sm rounded shadow-lg')
    .css({ 'display': 'none' });

    $(tooltip).insertAfter(this);

    // Position the tooltip
    const rect = this.getBoundingClientRect();
    const tooltipWidth = tooltip.outerWidth();
    const tooltipHeight = tooltip.outerHeight();
    let left = Math.max(rect.left + rect.width / 2 - tooltipWidth / 2, 15);
    const top = rect.top + window.scrollY - tooltipHeight - 4;

    // Check if tooltip is outside the viewport (right)
    const rightEdge = left + tooltipWidth;
    const viewportWidth = $(window).width();
    if (rightEdge > viewportWidth) {
      left = viewportWidth - tooltipWidth - 15;
    }

    tooltip.css({
      'left': `${left}px`,
      'top': `${top}px`
    }).fadeIn(200);

    // Remove the tooltip on mouse leave
    $(this).on('mouseleave', function() {
      tooltip.remove();
    });
  });
});