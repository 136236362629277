

$(() => {

  $(document).on('click', 'tr.clickable-row', function(e) {
    const href = $(this).data('href');
    if (href) {
      window.location = href;
    }
    return false;
  });

  $(document).on('click', 'tr.clickable-row a', function(e) {
    e.stopPropagation();
  });

});