
$(() => {

  // sort
  $('.table-sort').on('click', function() {
    const sort  = $(this).data('sort');
    const input = $('input[name=sort]');
    input.val(sort);
    input.closest('form').trigger('submit');
  });

});
